import React from "react"
import { graphql } from "gatsby"
import { Fade } from "react-awesome-reveal";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const EthosPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.banner.caption;
  const body = data.markdownRemark.html;

  return (
    <Layout navSolid="true">
      <Seo title={title} description={caption} />
      <section className="intro">
        <div className="container">
          <Fade triggerOnce>
            <h1>{title}</h1>
            <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
            <p className="lead">{caption}</p>
          </Fade>
        </div>
      </section>
      <section className="image-gallery">
        <Fade triggerOnce>
          <ul>
            <li>
              <StaticImage src="../../images/otago-girls-high-school-blazer.jpg" />
            </li>
            <li>
              <StaticImage src="../../images/queens-high-school-blazer.jpg" />
            </li>
            <li>
              <StaticImage src="../../images/logan-park-high-school-blazer.jpg" />
            </li>
          </ul>
        </Fade>
      </section>
      <section className="content">
        <div className="container">
          <Fade triggerOnce>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Fade>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "ethos"}}) {
    frontmatter {
      title
      banner {
        small_title
        caption
        banner_image {
          childImageSharp {
            gatsbyImageData(width: 900, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
    html
  }
}
`

export default EthosPage
